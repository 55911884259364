import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Web3 from "web3";
import { gingerBreadBoysABI } from "../../ABI/gingerBreadBoys";
import { useWeb3React } from "@web3-react/core";
import gif2 from "../../images/gif2.gif";
import bg from "../../images/MintImage.png";
import WalletButton from "../WalletConnect/WalletConnect"
import foot from "../../images/foot.png";
import BigNumber from "bignumber.js";


import downarrow from "../../images/downarrow.png";

const Wrapper = styled.section`
  // background: url(${bg}) no-repeat center center;
  // background-size: cover;
  padding: 11rem 0;
  text-align: center;

  @media all and (max-width: 1023px) {
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 5rem;
  }
  .wallet_button {
    color: #000;
    background-color: #fff;
    padding: 1rem;
    font-size: 1.8rem;
    font-family: "minecraft";
    margin-left: 2rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;
    margin: 0 auto 20px;
    &:hover {
      color: #000;
      background-color: #fff;
      border: 1px solid #fff;
    }
`;

const Image = styled.img`
  border: 5px solid #000;
`;

const H2 = styled.h2``;

const H4 = styled.h2`
  font-size: 1.8rem;
  margin: 0 auto;
`;

const Button = styled.button`
  color: #fff;
  background-color: #638596;
  padding: 1.5rem;
  font-size: 2.5rem;
  font-family: "minecraft";
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s all;
  margin-bottom: 2rem;

  &:hover {
    color: #638596;
    background-color: #fff;
    border: 1px solid #638596;
  }
`;

const Select = styled.select`
  background: url(/static/media/downarrow.9edfd30d.png);
  background-repeat: no-repeat;
  background-position: 84% center;
  background-color: #638596;
  background-size: 20px;
  appearance: none;
  outline: none;
  padding: 1.5rem;
  margin-right: 1rem;
  padding-right: 3.4rem;
  font-size: 2.5rem;
  font-family: "minecraft";
  cursor: pointer;
  color: #fff;
`;

const BakeNow = () => {
  const [num, setNum] = useState("???");
  const [mint, setMint] = useState(1);
  // console.log('mint',Number(mint))
  const [successfulPurchase, setsuccessfulPurchase] = useState(false);
  const [rewardSuccessfulPurchase, setRewardSuccessfulPurchase] =
    useState(false);
  const [accountConnected, setAccountConnected] = useState(false);
  const [hasError, setHasError] = useState("");
  const [rewardHasError, setRewardHasError] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [rewardTransactionHash, setRewardTransactionHash] = useState("");
  const [amount, setAmount] = useState("60000000000000000");
  const [price, setPrice] = useState("0");
  const [share, setShare] = useState("Connect wallet to view rewards");
  const { account, library } = useWeb3React();
  const optionsArray = [...Array(5).keys()];

  useEffect(async () => {
    const web3 = new Web3(Web3.givenProvider);
    const gingerBreadBoyscontract = new web3.eth.Contract(
      gingerBreadBoysABI,
      "0x45f85ebc46802E398D944bb0EB51f9E311757768"
    );
    gingerBreadBoyscontract.methods
      .totalSupply()
      .call()
      .then((resp) => {
        // console.log('resp',resp)
        setNum(resp);
      })
      // })
      .catch(function (error) {
        setNum("???");
      });
  }, []);

  function handleChange(event) {
    setMint(event?.target?.value);
  }

  const web3 = new Web3(Web3.givenProvider);
  const gingerBreadBoyscontract = new web3.eth.Contract(
    gingerBreadBoysABI,
    "0x45f85ebc46802E398D944bb0EB51f9E311757768"
  );

  gingerBreadBoyscontract.methods
    .aData()
    .call({ from: account })
    .then((resp) => {
      const string = resp[1].toString();
      const amount = web3.utils.fromWei(string, "ether");
      // console.log('(amount',Number(amount).toFixed(3))
      setShare(Number(amount).toFixed(2))
    })
    .catch(function (error) {
      setShare("Connect wallet to view rewards");
    });

  gingerBreadBoyscontract.methods
    .totalSupply()
    .call()
    .then((resp) => {
      // if (resp >= 0) {
      // setAmount(web3.utils.toWei("0.002", "ether"))
      // setPrice('0.002')
      // } else {
      setAmount(web3.utils.toWei("0", "ether"))
      setPrice('0')
      // }
      setNum(resp);
    })
    .catch(function (error) {
      setNum("???");
    });
    
    const mintNFT = async () => {
      let x = new BigNumber(String(mint * amount));
      // console.log('y',x.toFixed())
      setHasError("");
    setRewardHasError("");
    if (account) {
      setAccountConnected(true);
      const web3 = new Web3(library.provider);
      const gingerBreadBoyscontract = new web3.eth.Contract(
        gingerBreadBoysABI,
        "0x45f85ebc46802E398D944bb0EB51f9E311757768"
      );
      gingerBreadBoyscontract.methods
        .mint(mint)
        .send({
          from: account,
          value: x.toFixed(),
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("receipt", function (receipt) {
          setAccountConnected(true);
          setsuccessfulPurchase(true);
          setTransactionHash(receipt.transactionHash);
          setHasError("");
        })
        .on("error", function (error) {
          setHasError(error.message); // transaction rejected
          setsuccessfulPurchase(false);
        });
    } else {
      setAccountConnected(false);
    }
  };

  const reward = async () => {
    setRewardHasError("");
    setHasError("");
    if (account) {
      setAccountConnected(true);
      const web3 = new Web3(library.provider);
      const gingerBreadBoyscontract = new web3.eth.Contract(
        gingerBreadBoysABI,
        "0x45f85ebc46802E398D944bb0EB51f9E311757768"
      );
      gingerBreadBoyscontract.methods
        .claimMintRewards()
        .send({ from: account })
        .on("receipt", function (receipt) {
          setAccountConnected(true);
          setRewardSuccessfulPurchase(true);
          setRewardTransactionHash(receipt.transactionHash);
          setRewardHasError("");
        })
        .on("error", function (error) {
          setRewardHasError(error.message); // transaction rejected
          setRewardSuccessfulPurchase(false);
        });
    } else {
      setAccountConnected(false);
    }
  };

  return (
    <Wrapper id="mint">
      <div className="globalWrapper">
        <div className="grid">
          <div className="col-12">
            <WalletButton />
          </div>
          <div className="col-12">
<br />
            <H2>MINT</H2>
            {/* <p>Official Launch Date: 27th November, 2PM EST.</p> */}
            <p>Price per mint: FREE</p>
            <p>Total Minted: {num} / 7100</p>
            <Select name="dropdown" id="dropdown" onChange={handleChange}>
              {optionsArray.map(option => {
                const opt = option + 1
                return <option value={opt}>{opt}</option>
              })}
            </Select>
            <Button onClick={mintNFT}>MINT</Button>
            {/* <p>{mint * 0} TOPIA total</p> */}
            {/* <p style={{ fontSize: "12px" }}>Recommended: Metamask & MetaMask in-app browser<br /> or WalletConnect w/ TrustWallet.</p> */}
            <p style={{ fontSize: "12px" }}>{hasError ? <p>{hasError}</p> : ''}</p>
            <p style={{ fontSize: "12px" }}>{successfulPurchase ? <p>Success! <a style={{ fontSize: "12px", color: "white" }} href={`https://explorer.hychain.com/tx/${transactionHash}`} target="_blank" rel="noreferrer">Click to view your transaction</a></p> : ''}</p>


          <H4 className="py-5 text-center">
            <a
              className="link"
              href="https://explorer.hychain.com/address/0x45f85ebc46802E398D944bb0EB51f9E311757768"
              target="_blank"
            >
              SMART CONTRACT
            </a>
          </H4>
          </div>

          {/* <div className="col-6_sm-12">
            <div>
              <H2>REWARDS</H2>
              <p>50% of all mint funds, distributed to holders</p>
              <p>Your Unclaimed Hychain TOPIA: {share}</p>
              <Button onClick={reward}>CLAIM TOPIA</Button>
              {rewardSuccessfulPurchase ? (
                <p style={{ fontSize: "12px" }}>
                  Success!{" "}
                  <a
                    style={{ color: "white" }}
                    href={`https://explorer.hychain.com/tx/${transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click to view your transaction
                  </a>
                </p>
              ) : (
                ""
              )}
            </div>
          </div> */}
        </div>
        {/* <img src={foot} style={{margin: "5% auto 50px", maxWidth: "100%"}} /> */}
      </div>
    </Wrapper>
  );
};

export default BakeNow;
