import { useEffect, useState } from 'react';
import styled from "styled-components";
import { gingerBreadBoysABI } from "../../ABI/gingerBreadBoys";
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core'
import WalletConnect from "../WalletConnect/WalletConnect"

const Wrapper = styled.div`
  background-color: #fff;
  text-align: center;
  padding-bottom: 40px;
  p {
    margin: 0;
  }

  .nft-image {
    border: 4px solid black;
  }

  .wallet_button {
    color: #fff;
    background-color: #638596;
    padding: 1rem;
    font-size: 1.8rem;
    font-family: "Century-gothic";
    margin-left: 2rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: #638596;
      background-color: #fff;
      border: 1px solid #638596;
    }
  }
`;

const Bold = styled.span`
  font-weight: bold;
`

const Caps = styled.span`
  text-transform: capitalize;
`

const H1 = styled.h1`
`

const H3 = styled.h3`
  margin-bottom: 4rem;
`

const BoyWrapper = styled.div`
  border: 1px solid #638596;
  border-radius: 5px;
  padding: 1rem;
  background-color: #d9d9db;
`

const Loader = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #638596;
  border-right: 1.1em solid #638596;
  border-bottom: 1.1em solid #638596;
  border-left: 1.1em solid black;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  overflow: hidden;

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
`

const Space = styled.div`
  height: 5rem;
`

const MyCollection = () => {
  const { account, library } = useWeb3React()
  const [gingerBreadBoysPurchased, setGingerBreadBoysPurchased] = useState([]);
  const [mintedMessage, setMintedMessage] = useState('Connect to your wallet to see your collection of sloths.');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [loadedGingerBreadBoys, setLoadedGingerBreadBoys] = useState(0);
  const [totalGingerBreadBoys, setTotalGingerBreadBoys] = useState(0);

  const collectionNFT = async () => {
    const nb = []
    if (account) {
      let num = null
      const web3 = new Web3(library.provider);
      const gingerBreadBoyscontract = new web3.eth.Contract(gingerBreadBoysABI, "0x016e9eb8ad56ea91da4b758fcb0ed8ceb91d7b13");
      try {
        num = await gingerBreadBoyscontract.methods.tokensOfOwner(account).call();
      } catch (e) {
        setIsLoading(false);
        setError(`Please connect to the correct wallet.`);
      }

      if (typeof num !== Number && !num) {
        setError("Something went wrong. Please try again, and ensure you are connected to the right network.")
        setIsLoading(false);
        return
      } else if (num && num.length === 0) {
        setMintedMessage("Go purchase a Ginger Bread Boy to view it here!");
      } else if (num && num.length === 1) {
        setMintedMessage("You have minted 1 Ginger Bread Boy.")
      } else {
        setMintedMessage(`You have minted ${num.length} Ginger Bread Boys.`)
      };
      setTotalGingerBreadBoys(num.length)
      for (let i = 0; i < num.length; i++) {
        await fetch(`https://api.gingerbreadbsc.com/api/${num[i]}`)
          .then((response) => {
            if (response.ok) return response.json();
            throw new Error("Network response was not ok.");
          })
          .then(function (data) {
            const gingerBreadBoys = {
              tokenId: data.tokenId,
              name: data.name,
              image: data.image,
            };
            data.attributes.forEach((attribute) => {
              gingerBreadBoys[attribute.trait_type] = attribute.value;
            });
            setLoadedGingerBreadBoys(i + 1)
            nb.push(gingerBreadBoys)
          })
          .catch(error => {
            setError("Something went wrong. Please try again, and ensure you are connected to the right network.")
          })
          .finally(() => {
          })
      }
      setIsLoading(false);
    }

    nb.sort(function (a, b) { return a.tokenId - b.tokenId })
    setGingerBreadBoysPurchased(nb);
    return nb
  }

  useEffect(async () => {
    await collectionNFT();
  }, [account]);

  if (!account) {
    return <Wrapper id="howitworks">
      <div className="grid">
        <div className="col-12">
          <Space />
          <p>It seems you're not connected to the correct blockchain. Please connect to the correct wallet.</p>
          <Space />
          <div><WalletConnect /></div>
        </div>
      </div>
    </Wrapper>
  }

  if (error) {
    return <Wrapper id="howitworks">
      <div className="grid">
        <div className="col-12">
          <Space />
          <p>{error}</p>
        </div>
      </div>
    </Wrapper>
  }

  if (isLoading) {
    return <>
      <div className="grid bg-white">
        <div className="col-12">
          <Loader>Loading...</Loader>
          <H1 className="text-center">{loadedGingerBreadBoys}/{totalGingerBreadBoys} Ginger Bread Boys Loaded.</H1>
        </div>
      </div>
    </>
  }

  return (
    <Wrapper id="mycollection">
      <div className="globalWrapper">
        <Space />
        <H1>My GingerBreadBoys</H1>
        <Space />
        <H3>{mintedMessage}</H3>
        <div className="grid-equalHeight">
          {gingerBreadBoysPurchased.map((gingerBreadBoys) => {
            return (
              <div className="col-3_lg-4_md-6_sm-12" key={gingerBreadBoys.tokenId}>
                <BoyWrapper>

                  <img className="nft-image" id={gingerBreadBoys.name} src={gingerBreadBoys.image} alt="" />
                  {Object.keys(gingerBreadBoys).map(key => {
                    if (gingerBreadBoys[key] === gingerBreadBoys.image || gingerBreadBoys[key] === gingerBreadBoys.tokenId) return <></>
                    if (gingerBreadBoys[key] === gingerBreadBoys.name) {
                      return <Bold><Caps>GingerBreadBoy</Caps> {gingerBreadBoys[key]}</Bold>
                    }
                    return (
                      <p><Bold><Caps>{key}</Caps>:</Bold> {gingerBreadBoys[key]}</p>
                    )
                  })}
                </BoyWrapper>

              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  )
}

export default MyCollection;
